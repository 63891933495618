<template>
  <mcr-jlt-report v-if="isReportFirstVersion"></mcr-jlt-report>
  <family-website-page v-else-if="isReportSecondVersion"></family-website-page>
  <mcr-loading-indicator v-else :loading="true" message="Loading Report..."></mcr-loading-indicator>
</template>

<script>
import {mapGetters} from 'vuex';

import FamilyWebsitePage from '@/components/modules/familyWebsite/FamilyWebsitePage';
import McrJltReport from '@/components/modules/jlt_report/jlt.report';

export default {
  created() {
    this.$store.dispatch('fetchFamilyWebsiteListAction', this.username);
    let params = {
      username: this.username,
      siteName: this.siteName,
      partKey: this.partKey,
      keepImageAssetId: this.keepImageAssetId,
    };
    this.$store.dispatch('fetchFamilyWebsiteDetailsAction', params);
  },
  computed: {
    ...mapGetters(['familyWebsiteDetailsState', 'familyWebsiteListState']),
    username() {
      return this.$route.params.username;
    },
    siteName() {
      return this.$route.params.sitename;
    },
    partKey() {
      return this.$route.params.partkey || 'default';
    },
    isReportFirstVersion() {
      return (
        this.familyWebsiteListState && this.familyWebsiteDetailsState && this.familyWebsiteDetailsState.version == 1
      );
    },
    isReportSecondVersion() {
      return this.familyWebsiteDetailsState && this.familyWebsiteDetailsState.version == 2;
    },
    keepImageAssetId() {
      return this.isFWSReport ? 'true' : '';
    },
  },
  components: {McrJltReport, FamilyWebsitePage},
};
</script>

<style lang="scss" scoped></style>
