<template>
  <images-gallery-overlay-container :is-info-bar-left-side="true" :class="{'details-shown': areDetailsShown}">
    <div class="info-bar-content" slot="info-bar" slot-scope="props">
      <div class="info-bar-actions">
        <a class="toggle-details" @click="toggleDetailsShown">{{ toggleDetailsText }}</a>
      </div>
      <transition name="expand">
        <div v-show="areDetailsShown">
          <h5 class="title" v-if="props.item.title">{{ props.item.title }}</h5>
          <div class="counter" v-if="props.itemsCount > 1">
            {{ props.currentIndex + 1 }} of {{ props.itemsCount }} Photos
          </div>
          <dynamic-html
            v-if="props.item.description"
            :html="props.item.description"
            class="description"
            name="images-overlay-description"
          ></dynamic-html>
        </div>
      </transition>
    </div>
  </images-gallery-overlay-container>
</template>

<script>
import DynamicHtml from '@common/elements/familyWebsite/DynamicHtml';
import ImagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';

export default {
  data() {
    return {
      areDetailsShown: true,
    };
  },
  computed: {
    toggleDetailsText() {
      return this.areDetailsShown ? 'Hide' : 'Details';
    },
  },
  methods: {
    toggleDetailsShown() {
      this.areDetailsShown = !this.areDetailsShown;
    },
  },
  components: {ImagesGalleryOverlayContainer, DynamicHtml},
};
</script>

<style scoped lang="scss">
@import '@common/style/gallery.info';
.info-bar-content .info-bar-actions {
  justify-content: flex-start;
}
.info-bar-content .info-bar-actions a.toggle-details {
  color: #949494;
}
.title {
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.3em;
  padding-bottom: 8px;
  font-family: var(--report-accent-font);
}
.counter {
  color: #666;
  padding: 15px 0;
  @media only screen and (max-width: $breakpoint-desktop) {
    padding-top: 0;
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  $gallery-preview-mobile-top-bar-height: 90px;
  .images-gallery-overlay::v-deep {
    .content-container {
      .image-port {
        height: 100%;
      }
    }
  }
}
</style>
