<template>
  <div class="half_column_img" v-if="imagesStr !== 'undefined' && images && images.length >= 2">
    <img :src="images[0]" :sourceSrc="sourceImages[0]" :imageWidth="widths[0]" />
    <img :src="images[1]" :sourceSrc="sourceImages[1]" :imageWidth="widths[1]" />
  </div>
</template>

<script>
import {s3ThumbnailLink} from '@common/utils/utils';

export default {
  name: 'mac-half-column-images',
  props: ['imagesStr', 'widthsStr'],
  data() {
    return {
      splitTag: '【mcr】',
    };
  },
  computed: {
    sourceImages() {
      let arr = this.imagesStr.split(this.splitTag);
      var result = [];
      for (let a of arr) {
        if (a.indexOf('~@/') !== -1) {
          let imgSrc = `${a.replace('~@/assets/', '')}`;
          result.push(this.$getAsset('/assets/' + imgSrc));
        } else {
          result.push(a);
        }
      }
      return result;
    },
    images() {
      let arr = this.imagesStr.split(this.splitTag);
      var result = [];
      let index = 0;
      for (let a of arr) {
        if (a.indexOf('~@/') !== -1) {
          let imgSrc = `${a.replace('~@/assets/', '')}`;
          result.push(this.$getAsset('/assets/' + imgSrc));
        } else {
          if (this.widths[index] && this.widths[index] !== 'undefined' && parseInt(this.widths[index]) > 0) {
            result.push(s3ThumbnailLink(a, this.widths[index]));
          } else {
            result.push(a);
          }
        }
        index++;
      }
      return result;
    },
    widths() {
      let arr = this.widthsStr.split(this.splitTag);
      return arr;
    },
  },
};
</script>
<style lang="scss"></style>
