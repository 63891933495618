<template>
  <div class="jlt_report">
    <link href="https://fonts.googleapis.com/css?family=Assistant|Julius+Sans+One&display=swap" rel="stylesheet" />

    <div id="image_overlay">
      <div class="overlay_close"></div>
      <div id="overlay_quick_close"></div>
      <div id="image_port">
        <div class="image_display"></div>
        <div class="gallery_nav prev"></div>
        <div class="gallery_nav next"></div>
      </div>

      <div class="caption">
        <div class="image_info">
          <div class="gallery_title"></div>
          <div class="gallery_count"></div>
        </div>
        <div class="caption_content"></div>
      </div>

      <div class="caption_toggle">
        <span class="hide">&#8249; Hide Caption</span>
        <span class="show">&#8250; Show Caption</span>
      </div>
    </div>

    <!-- Set the top class of report : reportWrapperClass -->
    <div id="report_wrapper" :class="reportWrapperClass">
      <div id="toc_wrapper" v-show="templateType !== ''">
        <div id="menu" class="closed">
          <a
            v-for="data of menuData"
            :key="data.name"
            :class="username === data.username && sitename === data.name ? 'current' : ''"
            :href="'/report/' + data.username + '/' + data.name"
            >{{ data.display_name }}</a
          >
          <!-- FiledReport template image icon view -->
          <img v-if="shownReportMenu" :src="$getAsset('/assets/jlt_img/menu/1_gray.png')" />
        </div>
        <!-- Control the display of the report menu:  -->
        <div id="report_menu" v-if="shownReportMenu">
          <a
            v-for="part in processedSidebarConfig"
            :key="part.display_name"
            :href="'/report/' + username + '/' + sitename + '/' + (part.key === 'default' ? '' : part.key)"
          >
            <img :src="part.icon" />
            <img :src="part.hover_icon" class="hover" />
            {{ part.display_name }}
          </a>
        </div>
        <div id="toc"></div>
      </div>

      <div id="glossary">
        <!-- the glossary template key appended here -->
      </div>

      <div id="content_wrapper">
        <!-- loading -->
        <div class="report-img-wrap-spinner" v-if="loading">
          <mcr-loading-indicator :loading="loading" />
        </div>
        <!-- main report content -->
        <template v-if="showComponent">
          <component :is="htmlComponentName"></component>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import McrFacts from '@common/elements/familyWebsite/jlt/mcr_facts';
import McrGallery from '@common/elements/familyWebsite/jlt/mcr_gallery';
import McrHalfColumnImages from '@common/elements/familyWebsite/jlt/mcr_half_column_images';
import McrHeader from '@common/elements/familyWebsite/jlt/mcr_header';
import McrImage from '@common/elements/familyWebsite/jlt/mcr_image';
import McrCoverter from '@common/utils/showdown/jlt_report/mcrCoverter';
import sortBy from 'lodash/sortBy';
import Vue from 'vue';

import McrLeadingWords from './mcrComponents/mcr_leading_words';
import McrRemoteWiki from './mcrComponents/mcr_remote_wiki';
import McrSiblings from './mcrComponents/mcr_siblings';

// Existing report types
const MCRFWSTemplateType = {
  FiledReport: 'field_report',
  Reading: 'reading',
};

export default {
  name: 'mcr_jlt_report',
  data: function () {
    return {
      menuData: [],
      sidebarParts: [],
      parts: [],
      templateType: '',
      username: this.$route.params.username,
      sitename: this.$route.params.sitename,
      partkey: this.$route.params.partkey ? this.$route.params.partkey : 'default',
      htmlComponentName: 'mcr-report-page',
      showComponent: false,
      componentResolve: null,
      $tocItems: [],
      $sectionTitles: [],
      sectionTitlesId: {},
      galleryInfo: {
        selectedGallery: null,
        currentGalleryImage: null,
        prevGalleryImage: null,
        nextGalleryImage: null,
      },
      reportCache: {
        $tocItems: null,
        $sectionTitles: null,
        sectionTitlesId: {},
      },
      smoothScrolling: false,
      viewportHeight: 0,
      scrollLead: 0,
      lockTOC: false,
      newChapter: null,
      subSections: null,
      loading: false,
    };
  },
  components: {
    McrGallery,
    McrHeader,
    McrImage,
    McrHalfColumnImages,
    McrFacts,
    McrSiblings,
    McrRemoteWiki,
  },
  computed: {
    // Control the display of the left report menu
    shownReportMenu() {
      if (this.templateType === MCRFWSTemplateType.FiledReport) {
        return true;
      }
      return false;
    },
    // Set the top class of report
    reportWrapperClass() {
      return this.templateType;
    },
    // Set the top class of report content
    reportContentWrapperClass() {
      if (this.templateType === MCRFWSTemplateType.Reading) {
        return this.templateType + '_content chronicles';
      }
      return this.templateType + '_content';
    },
    processedSidebarConfig() {
      let result = [];
      for (let key of Object.keys(this.sidebarParts)) {
        let v = this.sidebarParts[key];
        v.key = key;
        result.push(v);
      }
      result = sortBy(result, function (o) {
        return o.tpl_priority;
      });
      return result;
    },
  },
  beforeCreate() {
    const src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.0/jquery.min.js';
    const integrity = 'sha256-BJeo0qm959uMBGb65z40ejJYGSgR7REI4+CW1fNKwOg=';
    const crossorigin = 'anonymous';

    let script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('integrity', integrity);
    script.setAttribute('crossorigin', crossorigin);
    script.addEventListener('load', () => {
      this.displayReport();
    });

    document.head.appendChild(script);
  },
  methods: {
    displayReport() {
      let that = this;
      Vue.component('mcr-report-page', function (resolve, reject) {
        that.componentResolve = resolve;
      });
      this.showComponent = true;
      this.reportInit();
      this.$nextTick(() => {
        this.fetchReportContent();
        this.handleCurrentMenu();
        this.handleMenuInteraction();
      });
    },
    // ========================================================================
    reportInit() {
      // Add report class to body to resolve CSS conflicts
      $('body').addClass('report');
      // If its a field report add .field_report
      if ($('#report_wrapper').hasClass('field_report')) {
        $('body').addClass('field_report');
      }
    },
    // ========================================================================
    fetchReportContent() {
      this.loading = true;
      this.menuData = sortBy(this.$store.getters.familyWebsiteListState, function (o) {
        return o.priority;
      });
      if (!this.sitename && this.menuData.length > 0) {
        this.sitename = this.menuData[0].name;
      }

      const res = this.$store.getters.familyWebsiteDetailsState;

      if (res) {
        this.templateType = res.template_type;
        this.sidebarParts = res.template_parts;
        this.parts = res.parts;
        let glossaryPart = res.parts['glossary'] ? res.parts['glossary'] : '';
        let content = res.parts[this.partkey] + '\n\n' + glossaryPart;
        let html = McrCoverter.makeHtml(content, this.username);
        let componentName = 'mcr-report-page';
        this.componentResolve({
          template: `<div id="content" class="${this.reportContentWrapperClass}">` + html + '</div>',
          name: componentName,
          components: {
            McrGallery,
            McrHeader,
            McrImage,
            McrHalfColumnImages,
            McrFacts,
            McrSiblings,
            McrRemoteWiki,
            McrLeadingWords,
          },
        });

        this.htmlComponentName = componentName;
        Vue.nextTick(() => {
          $('div.glossary').each(function (index) {
            // move glossary before doing everything else, so it will not display and disappear right away
            $(this).appendTo('#glossary');
          });
          this.loading = false;
          this.setupReport();
        });
      }
    },
    setupReport() {
      this.handleChroniclesH2();
      this.handleContentsTable();
      this.handleCacheTocAndSectionTitles();
      this.handleFootnotePlacement();
      this.handleCurrentMenu();
      this.handleExternalLinks();
      this.viewportHeight = $(window).height();
      this.scrollLead = Math.round(this.viewportHeight * 0.1);
      this.setBottomPadding();
      let that = this;
      setTimeout(() => {
        that.handleLocationHash();
        // 处理 mcr link
        that.handleMcrLink();
      }, 1000);
      this.handleSmoothScrollToTOC();
      this.handleWindowResize();
      // 处理 gallery image viewer
      this.handleGalleryAndImageViewer();
      // 处理footnote-ref
      this.handleFootnoteRef();
      // Menu Interaction
      this.handleMenuInteraction();
    },
    // ========================================================================
    // Add horizontal divider for all chapters
    handleChroniclesH2() {
      $('#content.chronicles h2').each(function (index) {
        if (index > 0) {
          $(this).before('<div class="divider"></div>');
        } else {
          $(this).before('<div class="divider first"></div>');
        }
      });
    },
    // Build Table of Contents
    handleContentsTable() {
      let that = this;
      $('#content h2, #content h3, #cover h2').each(function (index) {
        $(this).wrap('<a name="' + index + '" class="anchor"></a>');
        if ($(this).is('h2')) {
          that.newChapter = $('<div class="chapter chapter_container"></div>');
          $('#toc').append(that.newChapter);
          that.subSections = $('<div class="sub-sections"></div>');
          $(that.newChapter).append('<a href="#' + index + '" class="chapter">' + $(this).text() + '</a>');
          $(that.newChapter).append(that.subSections);
          if ($(this).hasClass('sticky_anchor')) {
            $(that.newChapter).addClass('sticky_anchor');
            $(that.newChapter).children('a').prepend('<span class="china_map_icon"></span>');
          }
        } else {
          $(that.subSections).append('<a href="#' + index + '" class="sub-section">' + $(this).text() + '</a>');
        }
      });
    },
    //Cache TOC & Section Titles
    handleCacheTocAndSectionTitles() {
      this.$tocItems = $('#toc a, #toc div');
      this.$sectionTitles = $('#report_wrapper .anchor');
      this.sectionTitlesId = {};
      let that = this;
      this.$sectionTitles.each(function () {
        var id = $(this).attr('name');
        that.sectionTitlesId[id] = $('#toc a[href=\\#' + id + ']');
      });
    },
    // Footnote placement (reversing what Typora does in export)
    handleFootnotePlacement() {
      $('.footnote-line').each(function () {
        var fnId = $(this).children('.md-fn-count').text();
        var fnRef = $("a[name='ref-footnote-" + fnId + "']");
        $(this).insertAfter(fnRef);
        fnRef.addClass('footnote-ref');
      });
    },
    // Sort current menu item to top
    handleCurrentMenu() {
      $('#menu .current').prependTo('#menu');
      $('#menu .current').html($('#menu .current').html() + '<span>&#9662;</span>');
    },
    // Make external links open in new tab
    handleExternalLinks() {
      $('#content a, #glossary .glossary a')
        .not('.footnote-ref, .glossary, .highlights a, .chinese_glossary')
        .attr('target', '_blank');
    },
    // ========================================================================
    // Highlight anchored link on load and scroll to it
    handleLocationHash() {
      let that = this;
      if (window.location.hash) {
        scroll(0, 0);
        var targetAnchor = location.hash.substring(1);
        var target = $('#report_wrapper .anchor[name=' + targetAnchor + '], #' + targetAnchor);
        if (target && target.offset()) {
          var targetTop = target.offset().top;
          this.smoothScrolling = true;
          $('html, body').animate(
            {
              scrollTop: targetTop,
            },
            800,
            function () {
              that.smoothScrolling = false;
              that.tocHighlight();
              that.tocAutoScroll();
            }
          );
        }
      } else {
        this.tocHighlight();
      }
      $(window).scroll(function (e) {
        e.stopPropagation();
        if (!that.smoothScrolling) {
          that.throttle(that.tocHighlight(), 200);
        }
      });
    },
    // ========================================================================
    // Smooth scroll to TOC or Cover link target
    handleSmoothScrollToTOC() {
      let that = this;
      $('#toc a, #introduction a').click(function (event) {
        //event.preventDefault();
        var targetHash = $(this).attr('href').substring(1);
        var isCoverLink = $(this).hasClass('ancestor');
        if (targetHash) {
          var target = $('#report_wrapper .anchor[name=' + targetHash + '], #' + targetHash);
          var targetTop = target.offset().top;

          that.smoothScrolling = true;
          $('html, body').animate(
            {
              scrollTop: targetTop,
            },
            800,
            function () {
              that.smoothScrolling = false;
              that.tocHighlight();
              if (isCoverLink) {
                that.tocAutoScroll();
              }
            }
          );
        }
      });
      // Prevent weird TOC scrolling behavior during animations (doesn't really work)
      $('#toc').mouseenter(function () {
        that.lockTOC = false;
      });
      $('#toc').mouseout(function () {
        that.lockTOC = true;
      });
      $('#toc').scroll(function (e) {
        if (that.lockTOC == true) {
          e.preventDefault();
        }
      });
    },
    // ========================================================================
    handleWindowResize() {
      let that = this;
      $(window).resize(function () {
        that.viewportHeight = $(window).height();
        that.setBottomPadding();
      });
    },
    // ========================================================================
    handleMcrLink() {
      this.handleGlossary();
      this.handleGlossaryStatic();
      // 查看所有wiki link，添加点击事件弹出wiki信息
      let aLinks = document.getElementsByTagName('a');
      for (let aLink of aLinks) {
        let mcrLink = aLink.getAttribute('mcrlink');
        let href = aLink.getAttribute('href');
        let cclass = aLink.getAttribute('class');
        let cclassArr = cclass ? cclass.split(' ') : [];
        if (mcrLink === 'true' || (cclassArr.indexOf('glossary') !== -1 && cclassArr.indexOf('wiki') !== -1)) {
          aLink.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.$store.commit('setGlossaryWikiNaturalIdState', null);
            setTimeout(() => {
              this.$store.commit('setGlossaryWikiNaturalIdState', href.replace('#', ''));
            }, 500);
          });
        }
      }
    },
    handleGlossary() {
      const that = this;
      // Open glossary
      $('a.glossary').click(function (event) {
        event.stopPropagation();
        event.preventDefault();
        $('#glossary .shown').removeClass('shown');
        let mcrLink = $(this).attr('mcrlink');
        if (mcrLink !== 'true' && !$(this).attr('class').split(/\s+/).includes('wiki')) {
          if ($($(this).attr('href')).length > 0) {
            $($(this).attr('href')).addClass('shown');
          }
          if ($('#' + $(this).attr('href')).length > 0) {
            $('#' + $(this).attr('href')).addClass('shown');
          }
        }
      });
      // Close glossary on click in content area
      $('#content').click(function () {
        $('#glossary .shown').removeClass('shown');
        that.$store.commit('setGlossaryWikiNaturalIdState', null);
        $('#content').removeClass('glossary-shown');
      });
      // Translation tooltips
      $('.translation .translation_en').click(function (e) {
        e.stopPropagation();
        $(this).parent().toggleClass('show_cn');
      });
      $('.translation .translation_cn').click(function (e) {
        e.stopPropagation();
      });
      // Close translation tooltip on click in content area
      $('#content').click(function () {
        $('.show_cn').removeClass('show_cn');
      });
    },
    handleGlossaryStatic() {
      const that = this;
      $('a.glossary-static').click(function (event) {
        event.stopPropagation();
        event.preventDefault();
        // order of commits matters
        that.$store.commit('setGlossaryWikiDataState', {
          content: $(this).attr('data-mcr-content'),
        });
        that.$nextTick(() => {
          that.$store.commit('setGlossaryWikiNaturalIdState', null);
        });
      });
    },
    // ========================================================================
    // Gallery and image viewer
    handleGalleryAndImageViewer() {
      let that = this;
      //Get image count for galleries
      $('#image_overlay').click(function () {
        $('#glossary .shown').removeClass('shown');
        that.$store.commit('setGlossaryWikiNaturalIdState', null);
        $('#content').removeClass('glossary-shown');
      });
      $('.gallery').each(function () {
        var galleryCount = $(this).children('.gallery_container').children('.gallery_image').length;
        $(this)
          .find('h3')
          .append('<span>' + galleryCount + ' Photos</span>');
      });
      //Images open themselves in a full screen overlay
      $('.img img, .half_column_img img, .gallery_image img').click(function (e) {
        e.preventDefault();
        let clone = $(this).clone();
        for (let c of clone) {
          if (c.attributes.imageWidth && c.attributes.sourceSrc) {
            c.src = '';
            c.src = c.attributes.sourceSrc.nodeValue;
          }
        }
        $('#image_overlay .image_display').html(clone);
        $('#image_overlay .caption .caption_content').html($(this).next('span').clone());
        that.handleMcrLink();
        $('#image_overlay').removeClass('no_caption');
        $('#image_overlay .caption_toggle').show();
        if ($(this).parent().parent().hasClass('gallery_container')) {
          that.galleryInfo.selectedGallery = $(this).parent().parent();
          that.galleryInfo.currentGalleryImage = $(this).parent();

          $('#image_overlay .caption .image_info .gallery_title').html(
            $(this).parent().parent().parent().find('h3').clone()
          );
        } else {
          that.galleryInfo.selectedGallery = false;
          if ($(this).next('span').length == 0) {
            $('#image_overlay .caption_toggle').hide();
            $('#image_overlay').addClass('no_caption');
          }
          $('#image_overlay #overlay_quick_close').show();
        }

        that.refreshGalleryViewer();
        $('#image_overlay').fadeIn(200);
      });

      $('#image_overlay .overlay_close, #overlay_quick_close').click(function () {
        $('#image_overlay').fadeOut(100);
        $('#image_overlay #overlay_quick_close').hide();
      });

      //Gallery image navigation and caption controls
      $('#image_overlay .gallery_nav').click(function () {
        if (!$(this).hasClass('disabled')) {
          if ($(this).hasClass('prev')) {
            if (that.galleryInfo.prevGalleryImage.length > 0) {
              $(that.galleryInfo.prevGalleryImage).children('img').click();
            }
          } else if ($(this).hasClass('next')) {
            if (that.galleryInfo.nextGalleryImage.length > 0) {
              $(that.galleryInfo.nextGalleryImage).children('img').click();
            }
          }
        }
      });

      $('#image_overlay .caption_toggle').click(function () {
        $('#image_overlay').toggleClass('no_caption');
      });

      //Gallery navigation with keyboard keys
      $('body').keydown(function (e) {
        if (e.keyCode == 37) {
          // left
          $(that.galleryInfo.prevGalleryImage).children('img').click();
        } else if (e.keyCode == 39) {
          // right
          $(that.galleryInfo.nextGalleryImage).children('img').click();
        }
      });
    },
    refreshGalleryViewer() {
      if (this.galleryInfo.selectedGallery) {
        $('#image_overlay .gallery_nav').show().removeClass('disabled');
        this.galleryInfo.prevGalleryImage = $(this.galleryInfo.currentGalleryImage).prev();
        this.galleryInfo.nextGalleryImage = $(this.galleryInfo.currentGalleryImage).next();
        if (this.galleryInfo.prevGalleryImage.length == 0) {
          $('#image_overlay .prev').addClass('disabled');
        }
        if (this.galleryInfo.nextGalleryImage.length == 0) {
          $('#image_overlay .next').addClass('disabled');
        }

        var galleryLength = $(this.galleryInfo.selectedGallery).children('.gallery_image').length;
        var imageIndex = $(this.galleryInfo.selectedGallery)
          .children('.gallery_image')
          .index(this.galleryInfo.currentGalleryImage);
        imageIndex++;
        $('#image_overlay .gallery_count').html(imageIndex + ' of ' + galleryLength + ' Photos');
      } else {
        $('#image_overlay .gallery_nav').hide();
        $('#image_overlay .gallery_count').html('');
      }
    },
    // ========================================================================
    // Toggle footnote on click
    handleFootnoteRef() {
      $('.footnote-ref').click(function (event) {
        event.preventDefault();
        var fnId = $(this).text();
        if (
          $(".footnote-line a[name='dfref-footnote-" + fnId + "']")
            .parent()
            .hasClass('footnote-shown')
        ) {
          $(".footnote-line a[name='dfref-footnote-" + fnId + "']")
            .parent()
            .removeClass('footnote-shown');
          setTimeout(function (event) {
            $(".footnote-line a[name='dfref-footnote-" + fnId + "']")
              .parent()
              .hide();
          }, 500);
        } else {
          $(".footnote-line a[name='dfref-footnote-" + fnId + "']")
            .parent()
            .show(0, function () {
              $(".footnote-line a[name='dfref-footnote-" + fnId + "']")
                .parent()
                .addClass('footnote-shown');
            });
        }
      });
    },
    // ========================================================================
    // Menu Interaction
    handleMenuInteraction() {
      $('#menu').hover(
        function () {
          $(this).removeClass('closed');
        },
        function () {
          $(this).addClass('closed');
        }
      );
      $('#menu').click(function () {
        $(this).toggleClass('closed');
      });
    },
    // ========================================================================
    // throttle for scrolling, enforces a minimum time interval
    throttle(fn, interval) {
      var lastCall, timeoutId;
      return function () {
        var now = new Date().getTime();
        if (lastCall && now < lastCall + interval) {
          // if we are inside the interval we wait
          clearTimeout(timeoutId);
          timeoutId = setTimeout(function () {
            lastCall = now;
            fn.call();
          }, interval - (now - lastCall));
        } else {
          // otherwise, we directly call the function
          lastCall = now;
          fn.call();
        }
      };
    },
    // Scroll TOC to currently viewed section
    tocAutoScroll() {
      let that = this;
      if (!this.smoothScrolling) {
        setTimeout(function (event) {
          if ($('#toc .expanded').position()) {
            var tocItemH = $('#toc .expanded').height();
            var tocItemOY = $('#toc .expanded')[0].getBoundingClientRect().top;
            var tocHeight = $(window).height() - 80;
            var tocScroll = $('#toc').scrollTop();
            if (tocItemOY - 80 < 0 || tocItemOY + tocItemH > tocHeight) {
              if (!that.smoothScrolling) {
                var tocScrollTarget = tocScroll + tocItemOY - 300;
                //log('need scrolling from ' + tocScroll + 'to ' + tocScrollTarget);
                that.smoothScrolling = true;
                $('#toc').animate(
                  {
                    scrollTop: tocScrollTarget,
                  },
                  400,
                  function () {
                    that.smoothScrolling = false;
                  }
                );
              }
            }
          }
        }, 500);
      }
    },
    // Highlight table of contents as user scrolls
    // IF YOU ARE READING THIS PLEASE OPTIMIZE THIS FUNCTION!! It gets slower the farther down in the content you get.
    tocHighlight() {
      let that = this;
      if (this.$sectionTitles.length > 0) {
        // get the current vertical position of the scroll bar
        var scrollPosition = $(window).scrollTop();
        // Find the current section
        var viewedSection;
        this.$sectionTitles.each(function (index) {
          var currentSection = $(this);
          // get the position of the section
          var sectionTop = currentSection.offset().top;
          // Check if this is where the user has scrolled to
          if (scrollPosition >= sectionTop - that.scrollLead) {
            viewedSection = currentSection;
          } else {
            // we have found our section, so we return false to exit the each loop
            return false;
          }
        });
        //Highlight the relevant TOC link
        // get the section id
        var id = viewedSection ? viewedSection.attr('name') : '';
        // get the corresponding TOC link
        var $viewedSection = this.sectionTitlesId[id];
        if ($viewedSection && !$viewedSection.hasClass('active')) {
          // if the link is not active, make it active
          this.$tocItems.removeClass('active');
          $viewedSection.addClass('active');
          //Expand section if not already expanded
          if (!$viewedSection.closest('div.chapter').hasClass('expanded')) {
            this.$tocItems.removeClass('expanded');
            $viewedSection.closest('div.chapter').addClass('expanded');
          }
        }
        //tocAutoScroll();
      }
    },
    // Add bottom padding so TOC works at bottom
    setBottomPadding() {
      var contentHeight = $('#content').height();
      var coverHeight = $('#cover').height();
      var lastTitle = $('#content h2:last-child, #content h3:last-child').last();
      if (lastTitle.length > 0) {
        var lastTitleY = lastTitle.offset().top;

        var lastSectionHeight = coverHeight + contentHeight - lastTitleY;

        if (lastSectionHeight < this.viewportHeight) {
          var newPadding = Math.round(this.viewportHeight - lastSectionHeight - lastTitle.height());
          $('#content').css('padding-bottom', newPadding + 'px');
        }
      }
    },
    // ========================================================================
  },
};
</script>

<style lang="scss">
@import './report';
</style>
