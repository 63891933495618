<template>
  <div :class="imageStyle !== 'undefined' ? imageStyle : ''">
    <img v-if="image !== 'undefined'" :src="formatImage" :sourceSrc="sourceFormatImage" :imageWidth="imageWidth" />
    <span v-if="caption !== 'undefined'" v-html="captionHtml"></span>
  </div>
</template>

<script>
import McrCoverter from '@common/utils/showdown/jlt_report/mcrCoverter';
import {s3ThumbnailLink} from '@common/utils/utils';

export default {
  name: 'mac-image',
  props: ['imageStyle', 'image', 'caption', 'imageWidth'],
  data() {
    return {
      captionHtml: '',
    };
  },
  computed: {
    sourceFormatImage() {
      if (this.image.indexOf('~@/') !== -1) {
        let imgSrc = `${this.image.replace('~@/assets/', '')}`;
        return this.$getAsset('/assets/' + imgSrc);
      } else {
        return this.image;
      }
    },
    formatImage() {
      if (this.image.indexOf('~@/') !== -1) {
        let imgSrc = `${this.image.replace('~@/assets/', '')}`;
        return this.$getAsset('/assets/' + imgSrc);
      } else {
        if (this.imageWidth && this.imageWidth !== 'undefined' && parseInt(this.imageWidth) > 0) {
          return s3ThumbnailLink(this.image, this.imageWidth);
        }
        return this.image;
      }
    },
  },
  mounted() {
    if (this.caption !== 'undefined') {
      this.captionHtml = McrCoverter.makeHtml(this.caption, this.$route.params.username);
    }
  },
};
</script>
<style lang="scss"></style>
