<script>
import MdHtmlConverter from '@common/utils/showdown/MdHtmlConverter';
import Vue from 'vue';

import {FIELD_REPORT_TEMPLATE_TYPE, READING_TEMPLATE_TYPE} from './constants';

function getOption(tag, html, components) {
  return {
    start: `<${tag}>`,
    end: `</${tag}>`,
    html,
    components,
  };
}

function generateComponent(context) {
  let html = MdHtmlConverter.makeHtml(context.children[0].text, context.props.username);
  const commonComponents = {
    McrLeadingWords: () => import('./components/McrLeadingWords'),
    McrRemoteWiki: () => import('./components/McrRemoteWiki'),
    McrImage: () => import('@common/elements/familyWebsite/McrImage'),
    McrHalfColumnImages: () => import('@common/elements/familyWebsite/McrHalfColumnImages'),
    McrGallery: () => import('./components/McrGallery'),
    mcrWikiGlossaryLink: () => import('@common/elements/glossary/mcrWikiGlossaryLink'),
    mcrStaticGlossaryLink: () => import('@common/elements/glossary/mcrStaticGlossaryLink'),
    McrLocalGlossaryLink: () => import('@common/elements/familyWebsite/McrLocalGlossaryLink'),
    McrPoemTable: () => import('./components/McrPoemTable'),
    McrPoem: () => import('./components/McrPoem'),
    McrYoutubeVideo: () => import('./components/video/McrYoutubeVideo'),
    McrIframeVideo: () => import('./components/video/McrIframeVideo'),
  };

  const mapping = {
    [FIELD_REPORT_TEMPLATE_TYPE]: getOption('field-report-template', html, {
      FieldReportTemplate: () => import('./templates/field_report/FieldReportTemplate'),
      ...commonComponents,
      McrHeader: () => import('./components/McrHeader'),
    }),
    [READING_TEMPLATE_TYPE]: getOption('reading-template', html, {
      ReadingTemplate: () => import('./templates/reading/ReadingTemplate'),
      ...commonComponents,
    }),
  };

  const options = context.props.isHtml
    ? getOption('field-report-html-part-template', html, {
        FieldReportHtmlPartTemplate: () => import('./templates/field_report/FieldReportHtmlPartTemplate'),
      })
    : mapping[context.props.template];

  return Vue.component('mcr-report-page', {
    template: `
        ${options.start}
          ${options.html}
        ${options.end}
        `,
    components: options.components,
  });
}

let compsCache = {};

export default {
  functional: true,
  render: function (createElement, context) {
    const cacheKey = `${context.props.username}-${context.props.siteName}-${context.props.partKey}`;

    if (!compsCache[cacheKey]) {
      compsCache[cacheKey] = generateComponent(context);
    }

    return createElement(compsCache[cacheKey], context.data, context.children);
  },
};
</script>
