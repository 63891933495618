<template>
  <ul class="siblings" v-html="formatLi">
    <!-- <ul class="facts" v-html="formatLi"> -->
    <!-- <li v-for="element of elements" v-html="element" :key="element">

    </li> -->
    <!-- </ul> -->
  </ul>
</template>

<script>
export default {
  name: 'mac-siblings',
  props: ['li'],
  data() {
    return {};
  },
  computed: {
    formatLi() {
      if (!this.li) {
        return;
      }
      return this.li.replace(/{/g, '<').replace(/}/g, '>').slice(4).slice(0, -5);
    },
  },
};
</script>
<style lang="scss"></style>
