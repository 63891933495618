<template>
  <div class="family-website-page">
    <link href="https://fonts.googleapis.com/css?family=Assistant|Julius+Sans+One&display=swap" rel="stylesheet" />
    <family-website-content
      v-if="templateType"
      :template="templateType"
      :username="username"
      :site-name="siteName"
      :part-key="partKey"
      :is-html="isHtmlPart"
    >
      {{ markdownContent }}
    </family-website-content>
    <mcr-loading-indicator v-else :loading="true" message="Loading Report..."></mcr-loading-indicator>
    <images-preview-overlay></images-preview-overlay>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import {mapGetters} from 'vuex';

import FamilyWebsiteContent from '@/components/modules/familyWebsite/FamilyWebsiteContent';
import ImagesPreviewOverlay from '@/components/modules/familyWebsite/components/ImagesPreviewOverlay';

import {templateTypes} from './constants';

export default {
  created() {
    const currentSite = this.familyWebsiteDetailsState;
    if (currentSite && (currentSite.name === this.siteName || (!this.siteName && currentSite.name === 'default'))) {
      return this.processWebsite();
    }
    this.$store.dispatch('fetchFamilyWebsiteListAction', this.username);
    this.init();
  },
  data() {
    return {
      templateType: '',
      markdownContent: '',
    };
  },
  watch: {
    '$route.params': {
      handler: function (toParams, fromParams) {
        if (!isEqual(toParams, fromParams)) {
          this.init();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['familyWebsiteListState', 'familyWebsiteDetailsState']),
    username() {
      return this.$route.params.username;
    },
    siteName() {
      return this.$route.params.sitename;
    },
    partKey() {
      return this.$route.params.partkey || 'default';
    },
    isHtmlPart() {
      return this.familyWebsiteDetailsState.html_parts.includes(this.partKey);
    },
  },
  methods: {
    init() {
      this.templateType = '';
      let params = {username: this.username, siteName: this.siteName, partKey: this.partKey, keepImageAssetId: 'true'};
      this.$store.dispatch('fetchFamilyWebsiteDetailsAction', params).then(() => {
        this.processWebsite();
      });
    },
    processWebsite() {
      const parts = this.familyWebsiteDetailsState.parts;
      let glossaryPart = parts['glossary'] ? parts['glossary'] : '';
      this.markdownContent = [parts[this.partKey], glossaryPart].join('\n\n');
      this.templateType = templateTypes[this.familyWebsiteDetailsState.template_type];
    },
  },
  components: {ImagesPreviewOverlay, FamilyWebsiteContent},
  name: 'FamilyWebsitePage',
};
</script>

<style lang="scss" scoped>
.family-website-page {
  display: flex;

  .template {
    background-color: var(--report-background);
    width: 100%;
    font-family: 'Assistant', sans-serif;
    line-height: 1.7;
    font-size: 19px;
  }
}
</style>
